@import 'base'
.Users
  +filters
  flex: 1

  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1
  section.content

    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em
      .Header, .Cell
        &.user
          flex: 1
        &.created
          width: 80px
          text-align: right
        &.id
          width: 80px
          text-align: left

        &.state
          width: 120px
          text-align: center

  .actionable
    cursor: pointer

//
// TODO: cleanup
//

.User
  +filters
  flex: 1
  > header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1

  main
    display: flex
    align-items: stretch
    section.content
      flex: 1
      .filters
        margin: 2em 2em 0

      // general styles for tables go here:
      .List
        margin-bottom: 2em
        .Header, .Cell
          &.id
            width: 100px
            text-align: left
          &.price, &.amount, &.fee, &.created_at, &.requested_at, &.paid_at, &.price
            width: 80px
            text-align: right
          &.actions
            width: 80px
            text-align: center
          &.listing, &.event, &.user
            flex: 1
            +ellipsize

          .listing-details, .user-details
            display: inline-block
            vertical-align: middle
            line-height: 1.1em

      // Special cases for tables go here:
      .Summary
        button svg
          +spin
        margin: 20px

        .report-exports, .actions, .settings
          .Toggle
            margin-right: 1em
          .alert
            display: inline-block
          ul
            list-style: none
            padding: 0
            li
              display: inline-block
              margin: 0 0.5em 0.5em 0
              svg
                margin-right: 0.5em
              a
                color: inherit

      .Chargebacks
        .List
          .Header, .Cell
            &.stripe-id
              width: 250px
              text-align: left
            &.amount, &.fee, &.date-of-dispute, &.status
              width: 80px
              text-align: right
            &.event
              flex: 1
              +ellipsize

          .Cell
            &.stripe-id, &.id
              text-align: left
              font-family: monospace

      .Emails
        .List
          .Header, .Cell
            &.subject
              flex: 1
              +ellipsize
            &.download, &.opens, &.clicks
              width: 40px
              text-align: center


      .PaymentProcessing
        .List
          .Header, .Cell
            &.flag
              width: 60px
            &.currencyName
              flex: 1
            &.method
              flex: 2
        .sync-container
          margin: 10px
          float: right


      .Balances
        .Header, .List
          .Header, .Cell
            text-align: center
            &.header
              font-weight: 400
              font-size: 15px
              padding: 5px 0
            &.negative
              color: $color-red
              font-weight: bold
            &.balance
              flex: 2
            &.create
              flex: 1
              .create-invoice-btn,
              .create-partnership-payment-btn,
              .create-advance-btn
                height: 30px
                width: 50px
                vertical-align: middle
              .create-invoice-btn
                width: 150px
            &.currency
              flex: 1
              +ellipsize
              .small-flag
                width: 25px
                margin-top: -5px
                margin-right: 8px

      .Subscriptions
        padding: 1em 2em
        .Subscription
          input[type='checkbox']
            margin-right: 1em
          label
            cursor: pointer
      .Plan
        padding: 1em 2em

  .CommissionCode
    h2
      margin: 20px
    .List
      .Header, .Cell
        &.currency, &.country, &.name, &.price, &.fees, &.commission-code, &.rebate-user
          flex: 1
          +ellipsize
          overflow: hidden
          white-space: nowrap
        &.code
          width: 135px
          +ellipsize
        &.fees
          display: flex
          flex: 2
      .rate-selector
        min-width: 150px
        border-top-right-radius: 0px
        border-bottom-right-radius: 0px

  .VAT
    h2
      margin: 20px
      margin-left: 0px
      padding-left: 0px
    .save-button-container
      text-align: right
      margin-top: 10px
      .btn
        margin-left: 10px
    .List
      .Header, .Cell
        flex: 1
        &.buttons
          width: 150px
          white-space: nowrap
          text-align: right
        &.toggle
          text-align: right
      .HeaderRow .Cell
        font-weight: bold
        margin: 0px 10px
      .rate-selector
        min-width: 250px
        border-top-right-radius: 0px
        border-bottom-right-radius: 0px
      .form-control
        min-width: 250px
        height: 36px

      &.modal-list
        height: 600px
        overflow-y: scroll

    .error
      color: #a94442
    .error-input
      &.form-control
        background-color: #f2dede
        border-color: #ebccd1
        color: #a94442
      .Select-control
        background-color: #f2dede
        border-color: #ebccd1
        color: #a94442

    .buttons
      float: right

    .btn
      margin-left: 8px

.WithdrawalModalContent
  box-sizing: border-box
  background-image: none
  .form-group
    min-height: 45px
    display: flex
    label
      width: 200px
    .form-control-static
      flex: 1
      padding-left: 20px
      pre
        width: 350px
